import { EventEmitter, Injectable, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConsoleService } from '../console/console.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  title: string;
  content: string;
  show: boolean = false;
  showButton: boolean = false;
  buttons = [];

  @Output() callbackNotification = new EventEmitter<string>();

  constructor(
    public translateService: TranslateService,
    private _consoleService: ConsoleService
  ) { }

  showNotification(title: string, content: string, callback: string = null, buttons = null, defaultButton = true) {
    this.buttons = [];
    this.title = title;
    this.content = content;
    this.showButton = true;
    this.show = true;
    if(defaultButton) {
      this.buttons = [
        { label: 'button_continue', callback: 'hideNotification', target: 'internal', color: 'btn-vini-primary' },
      ];
    }

    if(buttons){
      this.buttons = [...buttons, ...this.buttons];
    }
    this.callbackNotification.emit(callback);
  }

  emitCallback(callback: string, target: string) {
    if(target === 'internal') {
      this.hideNotification();
    } else {
      this.callbackNotification.emit(callback);
    }
    this.hideNotification();
  }

  setDefaultNotification() {
    this.translateService.get(['generic_error_title', 'generic_error_description']).subscribe(translations => {
      this.title = translations['generic_error_title'];
      this.content = translations['generic_error_description'];
    });
  }

  showDefault() {
    this.setDefaultNotification();
    this.buttons = [];
    this.showButton = true;
    this.show = true;
  }

  showLoading() {
    this.translateService.get(['loading_title', 'loading_description']).subscribe(translations => {
      this._consoleService.debug('NotificationService - showLoading', 'b', 'translations', translations);
      this.title = translations['loading_title'];
      this.content = translations['loading_description'];
      this.showButton = false;
      this.show = true;
    });

  }

  hideNotification() {
    this.show = false;
  }

  isShow() {
    return this.show;
  }
}
