import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomerFileService } from './shared/services/model/customerFile/customer-file.service';
import { SettingService } from './shared/services/model/setting/setting.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { ToolsService } from './shared/services/tools/tools.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'app-id';
  env = environment;

  constructor(
    private _translateService: TranslateService,
    private _toolsService: ToolsService,
    private _settingService: SettingService,
    private _router: Router
  ) {
  }

  ngOnInit() {
    this._settingService.all();
    localStorage.getItem('language') ? this._translateService.use(localStorage.getItem('language')) : this._translateService.use('fr');

    if (this.env.production && this._toolsService.getCookie('vini_access') !== 'authorized') {
      this._router.navigate(['/maintenance']);
    }
  }
}
