<div class="overflow-hidden" style="height: 100dvh; width: 100dvw; position: absolute; top: 0; left: 0; background-color: #F6F5F9;">
  <header class="header d-flex align-items-end justify-content-center text-center pb-5">
    <div>
      <img src="assets/images/icon-kyc.png" alt="Logo Vini" class="icon-header slideInTopAnimation mb-5">
      <h3 class="text-white slideInTopAnimation" [innerHTML]="'onboarding_1_title' | translate"></h3>
    </div>
  </header>
  <section class="content">
    <div class="container px-5 text-center slideInBottomAnimation">
      <p [innerHTML]="'onboarding_1_description' | translate"></p>
    </div>
  </section>
  <footer class="footer text-end">
    <div class="container p-5">
      <a [routerLink]="'/identification' + (this.token ? '/' + this.token : '')" class="btn btn-vini-primary btn-lg rounded-pill w-100 my-4 shadow slideInBottomAnimation">
        {{ 'onboarding_1_button' | translate }}
      </a>
    </div>
  </footer>

</div>
