import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth/auth.service';
import { ToolsService } from '../../shared/services/tools/tools.service';
import { environment } from '../../../environments/environment';
import { ConsoleService } from '../../shared/services/console/console.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  env = environment;
  loginField: string | undefined;
  passwordField: string | undefined;
  email: string = '';
  password: string = '';

  constructor(
    private _router : Router,
    private _authService: AuthService,
    private _toolsService: ToolsService,
    private _consoleService: ConsoleService
  ){

  }
  ngOnInit(): void {
    if (this.env.production && this._toolsService.getCookie('vini_access') !== 'authorized') {
      this._consoleService.debug('LoginComponent -  ngOnInit', 'b', "this._toolsService.getCookie('vini_access')", this._toolsService.getCookie('vini_access'));
      this._router.navigate(['/maintenance']);
      return;
    }

    if(this._authService.isAuthenticated()) this._router.navigate(["/revendeur"]);
  }

  login(){
    this._authService.login({email: this.loginField, password: this.passwordField});
  }
}
