<div style="width: 100dvw; background-color: #F6F5F9; overflow: hidden;" *ngIf="!ShowGreatings">
  <header class="header header-jeu d-flex align-items-end justify-content-center text-center p-5">
    <div class="jeuContent m-auto text-white">
      <h1>Jeu Vini<br/><span style="text-transform: uppercase">Identification en ligne</span></h1>
      <p>Tentez votre chance !</p>
      <p>
        Pour marquer le lancement de l'identification en ligne sur tous ses produits prépayés, Vini vous offre chaque semaine la chance de gagner de nombreux cadeaux : téléphones, écouteurs, sacs à dos, et plus encore !
      </p>
      <p>
        Pour jouer, c’est simple. Il vous suffit de compléter votre profil client sur id.vini.pf ou de remplir ce formulaire pour être automatiquement inscrit au tirage au sort.
      </p>
      <p>
        Bonne chance !
      </p>
      <p class="conditions">
        Les informations que vous communiquez sur ce formulaire sont collectées et traitées par ONATi en tant que responsable du traitement, conformément au Règlement UE 2016/679 du 27 avril 2016 (règlement général sur la protection des données « RGPD ») et à la loi n° 78-17 du 6 janvier 1978 modifiée (loi « Informatique et Libertés »). Ces données seront utilisées uniquement pour vous contacter dans le cadre de la gestion de ce jeu. Pour en savoir plus sur le traitement de vos données à caractère personnel et sur vos droits, vous pouvez consulter notre site internet www.vini.pf.
      </p>
      <p class="conditions">
        Règlement du jeu : <a href="https://www.vini.pf/jeu-identification-en-ligne-reglement" target="_blank">https://www.vini.pf/jeu-identification-en-ligne-reglement</a>
      </p>
    </div>
  </header>
  <section class="jeuContent m-auto">
      <div class="p-4">
        <form [formGroup]="customerForm" (ngSubmit)="onSubmit()" id="customerForm">
          <!-- Last Name -->
          <div class="mb-2 position-relative slideInLeftAnimation" [style.animationDelay]="'0.8s'">
            <label for="lastname">
              {{ 'Nom' }} <span class="mandatory">*</span>
            </label>
            <input type="text" id="lastname" class="form-control p-2" formControlName="lastname" [ngClass]="{ 'is-invalid': isFieldInvalid('lastname') }" />
            <div *ngIf="isFieldInvalid('lastname')" class="text-danger">
              <small *ngIf="customerForm.get('lastname').errors?.['required']">
                Le nom est requis.
              </small>
              <small *ngIf="customerForm.get('lastname').errors?.['pattern']">
                Le nom contient des caractères non valides.
              </small>
            </div>
          </div>

          <!-- First Name -->
          <div class="mb-2 position-relative slideInLeftAnimation" [style.animationDelay]="'1s'">
            <label for="firstname">
              {{ 'Prénom' }} <span class="mandatory">*</span>
            </label>
            <input type="text" id="firstname" class="form-control p-2" formControlName="firstname" [ngClass]="{ 'is-invalid': isFieldInvalid('firstname') }" />
            <div *ngIf="isFieldInvalid('firstname')" class="text-danger">
              <small *ngIf="customerForm.get('firstname').errors?.['required']">
                Le prénom est requis.
              </small>
              <small *ngIf="customerForm.get('firstname').errors?.['pattern']">
                Le prénom contient des caractères non valides.
              </small>
            </div>
          </div>

          <!-- Birthdate -->
          <div class="mb-2 position-relative slideInLeftAnimation" [style.animationDelay]="'1.2s'" style="position: relative; z-index: 9;">
            <label for="birthdate">
              {{ 'Date de naissance' }} <span class="mandatory">*</span>
            </label>
            <div class="input-group">
              <input type="text" id="birthdate" class="form-control p-2" ngbDatepicker formControlName="birthdate" placeholder="JJ/MM/AAAA" [minDate]="minDate" [maxDate]="maxDate" [ngClass]="{ 'is-invalid': isFieldInvalid('birthdate') }"  #datepicker="ngbDatepicker" />
              <button class="btn btn-outline-secondary" type="button" (click)="datepicker.toggle()">
                <i class="pi pi-calendar"></i>
              </button>
            </div>
            <div *ngIf="isFieldInvalid('birthdate')" class="text-danger">
              <small *ngIf="customerForm.get('birthdate').errors?.['required']">
                La date de naissance est requise.
              </small>
            </div>
          </div>

          <!-- Phone -->
          <div class="mb-2 position-relative slideInLeftAnimation" [style.animationDelay]="'1.4s'">
            <label for="phone">
              {{ 'Numéro de téléphone mobile' }} <span class="mandatory">*</span>
            </label>
            <input type="text" id="phone" class="form-control p-2" formControlName="phone" [ngClass]="{ 'is-invalid': isFieldInvalid('phone') }" />
            <div *ngIf="isFieldInvalid('phone')" class="text-danger">
              <small *ngIf="customerForm.get('phone').errors?.['required']">
                Le numéro de téléphone est requis.
              </small>
              <small *ngIf="customerForm.get('phone').errors?.['pattern']">
                Format invalide pour le numéro de téléphone.
              </small>
            </div>
          </div>

          <!-- Email -->
          <div class="mb-2 position-relative slideInLeftAnimation" [style.animationDelay]="'1.6s'">
            <label for="email">
              {{ 'Adresse mail' }} <span class="mandatory">*</span>
            </label>
            <input type="email" id="email" class="form-control p-2" formControlName="email" [ngClass]="{ 'is-invalid': isFieldInvalid('email') }"/>
            <div *ngIf="isFieldInvalid('email')" class="text-danger">
              <small *ngIf="customerForm.get('email').errors?.['required']">
                L'adresse mail est requise.
              </small>
              <small *ngIf="customerForm.get('email').errors?.['email']">
                Format d'adresse mail invalide.
              </small>
            </div>
          </div>

          <!-- Buttons -->
          <div class="row mt-4">
            <div class="col-12 col-lg-6 order-last order-lg-first">
              <button type="button" class="btn btn-vini-secondary-light btn-lg shadow rounded-pill w-100 mt-3 slideInBottomAnimation" [style.animationDelay]="'1.8s'" (click)="resetForm()">
                Réinitialiser
              </button>
            </div>
            <div class="col-12 col-lg-6 order-first order-lg-last">
              <button type="submit" class="btn btn-vini-primary btn-lg shadow rounded-pill w-100 mt-3 slideInBottomAnimation" [style.animationDelay]="'1.8s'">
                Participer
              </button>
            </div>
          </div>
        </form>
      </div>
  </section>
</div>
<div *ngIf="ShowGreatings">
  <div class="" style="height: 100dvh; width: 100dvw; position: absolute; top: 0; left: 0; background-color: #F6F5F9;">
    <header class="header d-flex align-items-end justify-content-center text-center pb-5">
      <div>
        <img src="assets/images/icon-trophy.png" alt="Logo Vini" class="icon-header slideInTopAnimation mb-5">
        <h3 class="text-white slideInTopAnimation">Félicitations {{ customerForm.get('firstname').value }} !</h3>
      </div>
    </header>
    <section class="content">
      <div class="container px-5 text-center slideInBottomAnimation">
        <p>
          Votre participation au jeu a bien été prise en compte.
        </p>
      </div>
    </section>
    <footer class="footer text-end">
      <div class="container p-5">
        <a href="https://www.vini.pf/" class="btn btn-vini-primary btn-lg rounded-pill w-100 my-4 shadow slideInBottomAnimation">Continuer</a>
      </div>
    </footer>

  </div>
</div>

<app-notification></app-notification>
