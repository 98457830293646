<div class="overlay" *ngIf="notificationService.isShow()">
  <div class="overlay-content text-center">
    <h2 [innerHTML]="notificationService.title"></h2>
    <div class="text-center" [innerHTML]="notificationService.content"></div>
    <ng-container *ngIf="notificationService.showButton">
      <div class="row mt-2">
        <div class="col-12" [ngClass]="getColClass()" *ngFor="let button of notificationService.buttons">
          <button class="btn btn-lg text-white shadow rounded-pill w-100 mt-3"  [ngClass]="button.color"
                  (click)="notificationService.emitCallback(button.callback, button.target)">
            {{ button.label | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
